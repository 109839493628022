<template>
  <div>
    <van-tabs v-model="tabActive" title-active-color="#387ff5" title-inactive-color="#999" color="#387ff5" @change="tabChange">
      <van-tab :title="tab.title" :name="tab.name" v-for="tab in tabList" :key="tab.id" >
        <div class="newListMain">
          <div class="cont">
            <div class="totalCount_2" v-if="dataList.length > 0">
              <p>共 <span>{{totalCount}}条</span> 数据</p>
            </div>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="20">
              <van-cell v-for="item in dataList" :key="item.id"  @click="getInfo(item.statusColor, item.id)">
                <div class="item">
                  <van-cell-group :border="false">
                    <van-cell :title="item.createTime"  class="title" center>
                      <span :class="textColor(item.statusColor)">{{item.statusStr}}</span>
                    </van-cell>
                    <van-cell class="itemCont"  :label="item.typeStr" is-link center  icon-prefix="widthImg" :icon="item.url?item.url:require('@/assets/img/grip.png')">
                      <template #title>
                        <span class="contTitle">{{item.subareaStr + item.houseName?item.houseName : ''}}</span>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
              </van-cell>
            </van-list>
            <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
          </div>
          <div class="addBtn" v-if="tabActive === '0'" @click="goAdd">
            <img :src="require('@/assets/img/add.png')" alt="">
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {getImageStream} from "@/utils";

export default {
  data () {
    return {
      tabActive: '0',
      totalCount: 0,
      tabList: [
        {id:1, title: '我发起的握一握', name: '0'},
        {id:2, title: '发给我的握一握', name: '1'}
      ],
      loading: false,
      finished: false,
      // dataList: [
      //   {id: 1, createDate: '2021-04-22 12:33', statusStr: '待回复', houseFullName: '善贤社区1-2-301', content: '明天上班,别吵', status: 1},
      //   {id: 2, createDate: '2021-04-22 12:33', statusStr: '已回复', houseFullName: '善贤社区1-2-301', content: '明天上班,别吵', status: 2},
      //   {id: 3, createDate: '2021-04-22 12:33', statusStr: '物业已介入', houseFullName: '善贤社区1-2-301', content: '明天上班,别吵', status: 3},
      //   {id: 4, createDate: '2021-04-22 12:33', statusStr: '问题已解决', houseFullName: '善贤社区1-2-301', content: '明天上班,别吵', status: 4}
      // ],
      dataList: [],
      page: 0,
      limit: 10
    }
  },
  methods: {
    textColor (status) {
      switch (status) {
        case 1:
          return 'text_b'
        case 2:
          return 'text_y'
        case 3:
          return 'text_r'
        case 4:
          return 'text_q'
      }
    },
    getInfo (status, id) {
      this.$store.commit('setGripData', {status, id, tabIndex: this.tabActive})
      this.$router.push('/grip-info')
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/future/communications/list'),
        method: 'post',
        params: this.$httpApp.adornParams({
          limit: this.limit,
          page: this.page,
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId,
          giveMe: this.tabActive
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.totalCount = data.page.totalCount
          if (this.dataList.length === data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          this.loading = false;
          this.$toast.clear()
        }
      })
    },
    tabChange () {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.getDataList()
    },
    goAdd () {
      this.$router.push('/grip-add')
    }
  },
  mounted() {
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
.widthImg {
  height: 100%;
  img {
    width: 90px;
    height: 90px;
  }
}
</style>
